import React, { forwardRef } from 'react';

import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import LogInIcon from '@atlaskit/icon/core/log-in';
import type { AnchorProps } from '@atlaskit/primitives/anchor';

import { ListItem } from '../../components/list-item';

import { LinkIconButton } from './themed/migration';

interface LogInProps extends Pick<AnchorProps, 'href'> {
	/**
	 * The label for the button
	 */
	label: React.ReactNode;
	onClick?: (e: React.MouseEvent<HTMLAnchorElement>, analyticsEvent: UIAnalyticsEvent) => void;
	/**
	 * Allows preloading popup components
	 */
	onMouseEnter?: React.MouseEventHandler<HTMLAnchorElement>;
	testId?: string;
	/**
	 * An optional name used to identify events for [React UFO (Unified Frontend Observability) press interactions](https://developer.atlassian.com/platform/ufo/react-ufo/react-ufo/getting-started/#quick-start--press-interactions). For more information, see [React UFO integration into Design System components](https://go.atlassian.com/react-ufo-dst-integration).
	 */
	interactionName?: string;
}
/**
 * __Log in__
 *
 * The Log in button for the top navigation.
 */
export const LogIn = forwardRef<HTMLAnchorElement, LogInProps>(
	({ onClick, onMouseEnter, testId, interactionName, href, label }, ref) => (
		/**
		 * This component doesn't use `UserAction` internally because it renders a
		 * `LinkIconButton` instead of an `IconButton`.
		 *
		 * We could make another abstraction, but this is the only top navigation item
		 * that renders a link.
		 */
		<ListItem>
			<LinkIconButton
				ref={ref}
				label={label}
				href={href}
				onClick={onClick}
				onMouseEnter={onMouseEnter}
				testId={testId}
				interactionName={interactionName}
				icon={LogInIcon}
				appearance="subtle"
				isTooltipDisabled={false}
			/>
		</ListItem>
	),
);
